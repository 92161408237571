import React, { ReactElement, useRef } from "react"
import VideoCard, {
  VideoCardProps,
} from "../../components/common/VideoCard/VideoCard"
import Button from "../../components/common/Button"
import { ArrowRightIcon } from "@heroicons/react/outline"
import { GatsbyGraphQLType, graphql, navigate, useStaticQuery } from "gatsby"
import { VideosGraphQLData } from "../../data/interfaces/video"

interface SectionProps {
  id?: string
  data?: VideosGraphQLData
}
const query = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
      filter: { fileAbsolutePath: { regex: "/videos/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            videoType
            description
            videolink
            date
            headerImage
            authorImage
            authorName
          }
        }
      }
    }
  }
`

function NewsSection({ id }: SectionProps): ReactElement {
  const data = useStaticQuery<VideosGraphQLData>(query)
  const news = data.allMarkdownRemark.edges.map(edge => edge.node)
  return (
    <section id={id} className="gradient-brand py-12 md:p-12">
      <div className="w-full m-auto flex flex-col justify-center items-center">
        <h1 className="text-5xl xs:text-6xl font-bold text-center">
          New Videos
        </h1>
        <ul className="flex my-4 flex-col lg:flex-row items-center lg:items-start w-11/12 lg:w-11/12 xl:w-9/12 flex-wrap justify-center">
          {news.map((newitem, index) => (
            <li key={newitem.id} className="m-4 news-item">
              <VideoCard
                id={newitem.id}
                {...newitem.frontmatter}
                imageSize={index == 1 ? "medium" : "large"}
              />
            </li>
          ))}

          <style scoped>{`
                       .news-item {
                           flex : 1 1 29% ;
                           max-width : max-content;
                       }
                    `}</style>
        </ul>
        <Button
          className=" py-4 px-6 "
          onClick={() => {
            navigate("/videos")
          }}
        >
          {" "}
          <span className="inline-block">View more</span>
          <ArrowRightIcon className="ml-4 h-5 w-5 inline" />
        </Button>
      </div>
    </section>
  )
}

export default NewsSection
