import React, { ReactElement, useEffect, useRef } from "react"
import HeroVideo from "../../video/Geelnet_Vid.mp4"
import Section from "../../components/common/Section"
import { navigate } from "gatsby-link"
interface HeroSectionProps {
  id: string
}
function HeroSection({ id }: HeroSectionProps): ReactElement {
  return (
    <Section id={id}>
      <div className="object-contain flex flex-col items-center justify-center h-screen w-full">
        {/* <iframe width="100%" height="100%" className="absolute object-cover h-full w-full" src="https://www.youtube.com/embed/akfGZRqH3ak?autoplay=1&controls=0&loop=1&showinfo=0&modestbranding=1" style={{
          zIndex: -1,
        }} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe> */}
        <video
          autoPlay
          loop
          playsInline
          style={{
            zIndex: -1,
          }}
          className="absolute object-cover h-full w-full"
        >
          <source key="video" src={HeroVideo} type="video/mp4" />
        </video>
        <div className="px-4 w-full  lg:w-1/2 text-center flex-col flex justify-center items-center">
          <h1 className="text-white-lighter font-extrabold text-4xl md:text-5xl lg:text-6xl">
            Welcome to Geelnet
          </h1>
          <h1 className="text-white-lighter font-semibold text-4xl md:text-2xl lg:text-3xl">
            A generation of Visual Stories
          </h1>
          <h6 className=" text-white-lighter text-sm leading-relaxed tracking-widest mt-5">
            WE ARE A DIGITAL-FIRST MEDIA MARKETING COMPANY PROVIDING EXCELLENT
            SERVICES IN DIGITAL MARKETING.
          </h6>
          <button
            onClick={e => {
              e.preventDefault()
              navigate("#brands")
            }}
            className="
         inline-block
         bg-brand-500 
         translate-y-0 transform font-bold transition-all ease duration-300 hover:-translate-y-2
         hover:shadow-brand mt-10 text-sm   text-black-default z-10   py-3 px-5 rounded-md"
          >
            Learn more
          </button>
        </div>
      </div>
    </Section>
  )
}

export default HeroSection
