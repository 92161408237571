import React, { ReactElement } from 'react'
import Button from '../../components/common/Button'
import { ArrowRightIcon } from "@heroicons/react/solid"
import { navigate } from 'gatsby'
import { CONTACT_ROUTE } from '../../constants/routes'
interface Props {
    id?: string
}


function GetInTouch({ id }: Props): ReactElement {
    return (
        <section id={id} className="px-4 py-20 flex flex-col text-center justify-center items-center w-full">
            <h1 className="text-5xl xs:text-6xl font-bold">Collaborate with us</h1>
            <p className="my-8 text-xl">interested in one of our services or have input for one of our shows?</p>
            <Button className=" py-4 px-6 " primary onClick={() => navigate(CONTACT_ROUTE)
            }> <div className="inline-block">
                    <span className="inline-block">Contact us </span>
                    <ArrowRightIcon className="ml-4 h-5 w-5 inline" />
                </div></Button>
        </section>
    )
}

export default GetInTouch
