import { BackspaceIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { yellowGradient } from '../../css/css'
import useHover from '../../hooks/useHover'

interface CardProps {
    icon : JSX.Element,
    stat : string,
    text: string,
    hoverColorClass : string,
    hoverContentClass : string,
    hoverIconClass : string
}

function SocialCard({
    icon,
    stat,
    text,
    hoverColorClass,
    hoverIconClass,
    hoverContentClass
}: CardProps): ReactElement {
    const [ref,value] = useHover()
    return (
        <div ref={ref} className="relative h-full flex flex-col px-10 py-8 border-2 border-black-default border-opacity-10 hover:border-transparent rounded-md" >
            <div className={classNames("absolute top-0 left-0 w-full h-full opacity-0 z-0 hover:opacity-100   transition-all duration-300 ease rounded-md",hoverColorClass)} ></div>
            <span className={classNames("relative z-10 pointer-events-none", {
                [hoverIconClass] : value
            })}>{icon}</span>
            <span className={classNames(" font-extrabold text-3xl my-4 relative z-10 pointer-events-none",{
                [hoverContentClass] : value
            })}>{stat}</span>
            <p className={classNames("z-10 pointer-events-none font-semibold text-2xl",{
                [hoverContentClass] : value
            })}>{text}</p>

        </div>
    )
}

export default SocialCard
