import {
  EyeIcon,
  VideoCameraIcon,
  UserGroupIcon,
} from "@heroicons/react/outline"
import React, { ReactElement } from "react"
import SocialCard from "./SocialCard"

interface Props {
  id?: string
}

function SocialShort({ id }: Props): ReactElement {
  return (
    <section
      id={id}
      className=" w-full lg:w-9/10 xl:w-8/10 m-auto flex flex-col p-4 md:p-12 text-black-default"
    >
      <div className="description p-4 flex-1 ">
        <span className="text-xs text-center block">ABOUT GEELNET</span>
        <h1 className="text-4xl my-4 font-bold text-center block">Geelnet in Numbers</h1>
        <p>
          GeelNet is an online video marketing agency Where our main business is
          to reach online audience and here is our proof we excel in that.
        </p>
      </div>

      <ul className="md:grid max-w-sm lg:max-w-none mx-auto md:mx-0 stats p-4 ">
        <li className="item h-full max-w-sm lg:max-w-none mb-4 md:mb-0" key={1}>
          <SocialCard
            icon={<VideoCameraIcon className="w-20"></VideoCameraIcon>}
            stat="+1 Bilion minutes watched"
            hoverColorClass="hover:gradient-brand"
            hoverContentClass="text-white-default"
            hoverIconClass="text-white-default"
          ></SocialCard>
        </li>
        <li className="item h-full mb-4 md:mb-0" key={2}>
          <SocialCard
            icon={<EyeIcon className="w-20"></EyeIcon>}
            stat="Over 300M views"
            hoverColorClass="hover:gradient-brand"
            hoverContentClass="text-white-default"
            hoverIconClass="text-white-default"
          ></SocialCard>
        </li>
        <li className="item h-full mb-4 md:mb-0" key={3}>
          <SocialCard
            icon={<UserGroupIcon className="w-20"></UserGroupIcon>}
            stat="1400000 SUBSCRIBERS"
            hoverColorClass="hover:gradient-brand"
            hoverContentClass="text-white-default"
            hoverIconClass="text-white-default"
          ></SocialCard>
        </li>
      </ul>

      <style scoped>
        {`
                    .description {
                        flex : 2;
                    }
                    .stats {
                        flex : 4;
                        grid-template-columns: repeat(3 ,1fr);
                        align-items: end;
                        grid-column-gap: 20px;
                        grid-row-gap: 20px;
                    }
                    .item {
                       
                    }
                `}
      </style>
    </section>
  )
}

export default SocialShort
